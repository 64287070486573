<template>
  <el-dialog v-model="viewVisible" width="800px" :show-close="false" class="ep-dialog" :append-to-body="true" :close-on-click-modal="false" @open="onViewOpen">

    <template #header="{ titleId, titleClass }">
      <div class="m-header">
        <span :id="titleId" :class="titleClass">供货货品变动详情：</span>
          <!-- <el-tag v-if="orderData.oms_order_type == 10" type="success">分销订单</el-tag><el-tag v-else-if="orderData.oms_order_type == 20" type="success">供应订单</el-tag> -->
        <!-- </span> -->
        <div>
          <!-- <el-button type="primary" plain><el-icon><Link/></el-icon><span>在新窗口打开</span></el-button> -->
          <el-button plain @click="viewVisible = false"><el-icon><Close/></el-icon><span>关闭</span></el-button>
        </div>
      </div>
    </template>

    <el-card>
      <el-skeleton :rows="8" :loading="epIsLoading" animated>
        <template #default>
          <template v-if="logsData.length > 0">
            <div style="height: 400px; overflow-y: scroll;">
              <el-timeline style="margin-top: 20px; max-width: 600px">
                <el-timeline-item v-for="(activity, index) in logsData" :key="index" :timestamp="activity.timestamp"
                  :color="index==0?'#0bbd87':''" :hollow="index==0?false:true" :type="index==0?'':'primary'">
                  {{ activity.changelog_desc }}
                </el-timeline-item>
              </el-timeline>
            </div>  
          </template>
          <template v-else>
            <el-empty description="暂无数据" />
          </template>
        </template>
      </el-skeleton>

    </el-card>

    <!-- <template #footer>
      <el-button type="primary" @click="onSubmit"><el-icon><Check/></el-icon><span>提交</span></el-button>
      <el-button type="default" @click="viewVisible = false"><el-icon><Close/></el-icon><span>取消</span></el-button>
    </template> -->
  </el-dialog>
</template>

<script>
import DataTableMixin from '@/mixin/DataTableMixin.js'
import { Link, Check, Close } from '@element-plus/icons-vue'
import SourceCodeTag from '@/components/v1/jxgocs/tags/SourceCodeTag'
import { apiV1FxGoodsItemsActionShowChangelogs } from '@/api/fenxiao.js'


export default {
  mixins: [ DataTableMixin ],
  name: 'order-detail-dialog',
  components: {
    Link, Check, Close,
    SourceCodeTag,
  },
  emits: ['submit'],
  props: [],
  data() {
    return {
      editMode: 'insert',
      viewVisible: false,
      isLoading: false,

      sku_id: '',
      sup_goods_item_id: '',
      logsData: [],

      // 检验规则
      formRules: {
      },
      // 拷贝规则
      cloneFields: [
      ],
    }
  },
  computed: {
  },
  methods: {
    // 显示view
    showView({ editMode, id, id2 } = {}) {
      this.editMode = editMode || 'insert'
      this.sku_id = id
      this.sup_goods_item_id = id2
      // this.order_sn = order_sn
      this.viewVisible = true
    },
    // 当View打开时
    onViewOpen() {
      this.epIsLoading = true
      this.logsData = []
      apiV1FxGoodsItemsActionShowChangelogs({
        sku_id: this.sku_id,
        sup_goods_item_id: this.sup_goods_item_id,
        //
        show_mode: 'merchant',
      }).then((res) => {
        this.logsData = res.data.data
        this.$emit('submit', this.editMode)
        this.epIsLoading = false
      })
    },
    

    // ===================================================================================================

  },
  mounted() {
  }
};
</script>

<style scoped>
.m-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.m-amount-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* color: #303133; */
}
.m-discount-text {
  color: #67C23A;
}
.m-total-text {
  color: #f56C6C;
  font-weight: 700;
}
</style>
