<template>
  <el-dialog v-model="viewVisible" width="1200px" :show-close="false" class="ep-dialog" :append-to-body="true" :close-on-click-modal="false" @open="onViewOpen">

    <template #header="{ titleId, titleClass }">
      <div class="m-header">
        <span :id="titleId" :class="titleClass">图片上传</span>
        <div>
          <!-- <el-button type="primary" plain><el-icon><Link/></el-icon><span>在新窗口打开</span></el-button> -->
          <el-button plain @click="onClose"><el-icon><Close/></el-icon><span>关闭</span></el-button>
        </div>
      </div>
    </template>
    <el-card>
      <el-upload name="imageFile" v-model:file-list="fileList" 
        :action="uploadUrl" list-type="picture-card" accept="image/*" 
        :data="imageUploadData" :headers="{'x-request-token':'50f92b9ddec0e275a191997a790fdf96', 'x-timestamp':1413388800}"
        :before-upload="beforeUpload" :on-success="onUploadSuccess"
        :on-preview="handlePictureCardPreview" :on-remove="handleRemove">
        <el-icon><Plus /></el-icon>
      </el-upload>
    </el-card>

    <!-- <template #footer>
      <el-button type="primary" @click="onSubmit"><el-icon><Check/></el-icon><span>提交</span></el-button>
      <el-button type="default" @click="viewVisible = false"><el-icon><Close/></el-icon><span>取消</span></el-button>
    </template> -->
  </el-dialog>
</template>

<script>
import DataTableMixin from '@/mixin/DataTableMixin.js'
import { Link, Check, Close, Picture as IconPicture, Plus } from '@element-plus/icons-vue'
import SourceCodeTag from '@/components/v1/jxgocs/tags/SourceCodeTag'
import { apiV1ImageActionPreUpload } from '@/api/file.js'
import AuthUtils from '@/utils/auth-utils.js'
import GlobalConfig from '@/config/index.js'


export default {
  mixins: [ DataTableMixin ],
  name: 'token-detail-dialog',
  components: {
    Link, Check, Close, IconPicture, Plus,
    SourceCodeTag,
  },
  props: [],
  data() {
    return {
      editMode: 'insert',
      viewVisible: false,
      isLoading: false,

      shop_id: '',
      omsShopData: [],
      //
      uploadUrl: GlobalConfig.host + 'api/common/v1/file/image/actions/upload',
      uploaderToken: '',
      fileList: [],
      imageUploadData: [],


      // 检验规则
      formRules: {
        // name: [{required: true, message: '请输入中文名', trigger: 'blur' }, {max: 20, message: '中文名长度不超过 20 个字符', trigger: 'blur'}],
        // staff_code: [{required: true, message: '员工编码', trigger: 'blur'}],
      },
      // 拷贝规则
      cloneFields: [
        // 'name', 'name_en', 'staff_code', 'campus_id', 'mobile', 'email', 'department_id', 'staff_type_id',
        // {key: 'sex', default: 0},
      ],
    }
  },
  computed: {
  },
  methods: {
    // 显示view
    showView({ editMode, id } = {}) {
      this.editMode = editMode || 'insert'
      this.shop_id = id
      this.viewVisible = true
    },
    // 当View打开时
    onViewOpen() {

      this.epIsLoading = true

      // apiV1GetPlatformShopToken({
      //   shop_id: this.shop_id,
      // }).then((res) => {
      //   // this.formModel = this.$$utils.cloneObject(res.data.data, this.cloneFields)
      //   this.omsShopData = res.data.data
      //   this.epIsLoading = false
      // })
    },

    handleRemove(uploadFile) {
      console.log(uploadFile)
    },

    handlePictureCardPreview(uploadFile) {
      console.log(uploadFile)
    },
    // 图片上传前触发
    beforeUpload(rawFile) {
      if (rawFile.size / 1024 / 1024 > 2) {
        this.$message.error('上传图片大小不能超过2MB!')
        return false
      }
    },
    // 图片上传成功
    onUploadSuccess(response, uploadFile, uploadFiles) {
      if (response.code == -1) {
        this.$message.error('上传图片失败。' + response.msg)
      } else {
        this.$message.success('上传图片成功')
      }
    },
    // 初始化主图上传框
    initMainImageUploader() {
      if (this.uploaderToken == '') {
        apiV1ImageActionPreUpload().then((res) => {
          this.uploaderToken = res.data.token;
          this.imageUploadData = {
            token: this.uploaderToken,
            merchant_id: AuthUtils.getCurrentMerchantpId(),
          }
        })
      } else {
        this.imageUploadData = {
          token: this.uploaderToken,
          merchant_id: AuthUtils.getCurrentMerchantpId(),
        }
      }
    },

    onClose() {
      this.viewVisible = false
      this.$emit('submit')
    },
    

    // ===================================================================================================


  },
  mounted() {
    this.initMainImageUploader()
  }
};
</script>

<style scoped>
.m-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.m-main-image{ width:80px; height:80px; margin: 4px 0; }
.m-main-image .el-image { padding: 4px;  max-width: 80px;  max-height: 80px;  width: 100%;  height: 80px; }
.m-main-image .image-slot { display: flex;  justify-content: center;  align-items: center;  width: 100%;  height: 100%;  background: #d4d7de;  color: var(--el-text-color-secondary);  font-size: 30px; }
.m-main-image .image-slot .el-icon { font-size: 30px; }
</style>
