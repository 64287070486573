<template>
  <el-card :body-style="{padding:'0px'}">
    <!-- 其他窗体 START -->
    <!-- <OrderExportDialog ref="refOrderExportDialog" /> -->
    <!-- 其他窗体 END -->
    <el-form class="ep-search-panel" ref="searchForm" label-position="top">

      <!-- 普通查询部分 START -->
      <el-row class="m-common-field" :gutter="20">
        <el-col :span="6">
          <el-form-item class="m-common">
            <template #label><span class="ep-search-panel-field-label">订单查询</span></template>
            <el-input placeholder="请输入查询内容" v-model="searchContent1" class="ep-search-form-input-with-select m-search-form-input-with-select">
              <template #prepend>
                <el-select v-model="searchKey1" placeholder="请选择">
                  <el-option key="fxp_order_sn" label="分销采购单号" value="fxp_order_sn" />
                  <el-option key="fx_order_sn" label="分销订单号" value="fx_order_sn" />
                  <!-- <el-option key="waybill_number" label="运单号" value="waybill_number" /> -->
                </el-select>
              </template>
            </el-input>
          </el-form-item>
        </el-col>

        <el-col :span="6">
          <el-form-item>
            <template #label><span class="ep-search-panel-field-label">供应商</span></template>
            <el-select v-model="sup_shop_id" filterable clearable placeholder="请选择" style="width:100%">
              <el-option v-for="item in shopPairs" :key="item.shop_id" :label="item.shop_name" :value="item.shop_id">
                <span class="ep-select-option-left">{{ item.shop_name }}</span>
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        
        <el-col :span="6">
          <el-form-item>
            <template #label><span class="ep-search-panel-field-label">下单时间</span></template>
            <el-date-picker v-model="createdTime" type="datetimerange"
              :picker-options="pickerOptions" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" align="right">
            </el-date-picker>
          </el-form-item>
        </el-col>

        <!-- <el-col :span="6">
          <el-form-item>
            <template #label><span class="ep-search-panel-field-label">结算状态</span></template>
            <el-select v-model="oms_fxp_order_state" placeholder="订单状态" style="width:100%">
              <el-option label="全部" value="__ALL__">全部</el-option>
              <el-option label="未结算" value="0">未结算</el-option>
              <el-option label="已结算" value="1">已结算</el-option>
            </el-select>
          </el-form-item>
        </el-col> -->

        <!-- <el-col :span="6">
          <el-form-item>
            <template #label><span class="ep-search-panel-field-label">订单类型</span></template>
            <el-select v-model="oms_order_type" placeholder="订单类型" style="width:100%">
              <el-option label="全部" value="__ALL__">全部</el-option>
              <el-option label="普通订单" value="0">普通订单</el-option>
              <el-option label="分销订单" value="10">分销订单</el-option>
              <el-option label="供应订单" value="20">供应订单</el-option>
            </el-select>
          </el-form-item>
        </el-col> -->


      </el-row>
      <!-- 普通查询部分 END -->

      <!-- 高级查询部分 START -->
      <div class="m-advance-filed" v-show="this.advanceToggle">
        <!-- <div class="ep-search-form-item-row">
          <div class="ep-search-form-item-col1">
            <el-form-item label="商户编码：">
              <el-input v-model="searchModel.merchant_code" />
            </el-form-item>
          </div>
          <div class="ep-search-form-item-col1">
            <el-form-item label="商户编码：">
              <el-input v-model="searchModel.merchant_code" />
            </el-form-item>
          </div>
          <div class="ep-search-form-item-col1">
            <el-form-item label="商户编码：">
              <el-input v-model="searchModel.merchant_code" />
            </el-form-item>
          </div>
        </div> -->
      </div>
      <!-- 高级查询部分 END -->

      <!-- 功能按键组 START -->
      <div>
        <el-button type="primary" @click="onSearchClick">查询</el-button>
        <el-button type="primary" plain @click="onAdvanceToggle">高级查询</el-button>
        <el-button type="primary" plain @click="onResetClick">重置</el-button>

        <span class="ep-form-button-separation" />

        <!-- <el-button type="primary" @click="onExportClick">导出</el-button> -->
        <el-dropdown split-button type="primary" @click="onExportClick">
          按商品导出
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item @click="onExportClick2">按订单导出</el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
        <!-- <el-dropdown split-button type="primary" @click="onExportAllClick" >
          导出所有项
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item>导出选中项</el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown> -->
        <!-- <el-button type="primary" plain @click="onExportTaskListClick" style="margin-left: 12px;">导出记录<el-icon class="el-icon--right"><MoreFilled/></el-icon></el-button> -->

        

        

        <!-- <el-dropdown split-button type="primary" @click="onSearchClick">
          <span>查询</span>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item @click="onAdvanceToggle">高级查询</el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown> -->
      </div>
      <!-- 功能按键组 END -->
  
    </el-form>
  </el-card>
</template>

<script>
import { ArrowDown, MoreFilled } from '@element-plus/icons-vue'
// import OrderExportDialog from '@/views/v1/trade/order/list/OrderExportDialog'
// import { apiV1ExportTaskActionCreate } from '@/api/task.js'
import { apiV1GetShopPairs } from '@/api/merchant.js'


export default {
  name: "order-search-panel",
  components: {
    ArrowDown, MoreFilled,
    // OrderExportDialog,
  },
  props: [],
  emits: ['search', 'reset', 'export', 'export2'],
  data() {
    return {
      // 高级查询切换标记
      advanceToggle: false,
      // 查询字段 - 订单搜索
      searchKey1: 'fxp_order_sn',
      searchContent1: '',
      //
      //
      shopPairs: [],
      sup_shop_id: '',
      // 查询字段 - 下单时间
      createdTime: [],

      // 时间选择快捷方式
      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近三个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        }]
      },
      
      
    };
  },
  computed: {},
  watch: {},
  methods: {
    // 点击触发 - 查询
    onSearchClick() {
      var searchData = this.getSearchFilter()
      this.$emit('search', searchData)
    },
    // 点击触发 - 高级查询
    onAdvanceToggle() {
      this.advanceToggle = !this.advanceToggle
    },
    // 点击触发 - 重置
    onResetClick() {
      this.searchContent1 = ''
      this.sup_shop_id = ''
      this.createdTime = []

      this.$emit('reset', {})
    },

    onExportClick() {
      var searchData = this.getSearchFilter()
      this.$emit('export', searchData)
    },
    onExportClick2() {
      var searchData = this.getSearchFilter()
      this.$emit('export2', searchData)
    },

    onExportAllClick() {
      var searchData = this.getSearchFilter()
      // apiV1ExportTaskActionCreate({
      //   task_type: 'admin.order.export',
      //   opuser_type: 'admin',
      //   task_query_params: searchData,
      // }).then((res) => {
      //   // this.getPage()
      //   this.$message.success("操作成功")
      //   this.onExportTaskListClick()
      // })

      
    },
    // 点击 导出记录
    onExportTaskListClick(id) {
      // this.$refs.refOrderExportDialog.showView({ editMode: "insert" })
    },
    
    // ================================================================================================
    // 获取查询filter
    getSearchFilter() {
      let searchFilter = {}
      if (this.searchKey1.trim() != '' && this.searchContent1.trim() != '') {
        searchFilter = {[this.searchKey1]: this.searchContent1}
      }
      if (this.sup_shop_id != '' && this.sup_shop_id != null) {
        searchFilter.sup_shop_id = this.sup_shop_id
      }
      if (this.createdTime != null && this.createdTime.length > 0) {
        var start_created = parseInt(this.createdTime[0].getTime() / 1000)
        var end_created = parseInt(this.createdTime[1].getTime() / 1000)

        searchFilter.order_time_start = start_created
        searchFilter.order_time_end = end_created
      }

      return searchFilter
    },


    //

    getShopData() {
      if (this.shopPairs.length == 0) {
        apiV1GetShopPairs({shop_type: 1}).then((res) => {
          this.shopPairs = res.data.data;
        })
      }
    },
    
    
  },
  mounted() {
    // 初始化时间 最近24小时
    // const createdTimeStart = new Date();
    // createdTimeStart.setTime(createdTimeStart.getTime() - 3600 * 24 * 365 * 1000);
    // this.createdTime = [createdTimeStart, new Date()]
    this.getShopData()
  }
};
</script>

<style scoped>
.m-search-form-input-with-select .el-select { width: 150px !important;}
</style>
