<template>
  <el-dialog v-model="viewVisible" width="1200px" :show-close="false" class="ep-dialog" :append-to-body="true" :close-on-click-modal="false" @open="onViewOpen">

    <template #header="{ titleId, titleClass }">
      <div class="m-header">
        <span :id="titleId" :class="titleClass">分销采购单详情：<span v-if="epIsLoading">加载中...</span><span v-else>{{ orderData.fxp_order_sn }}</span>
          <!-- <el-tag v-if="orderData.oms_order_type == 10" type="success">分销订单</el-tag><el-tag v-else-if="orderData.oms_order_type == 20" type="success">供应订单</el-tag> -->
        </span>
        <div>
          <!-- <el-button type="primary" plain><el-icon><Link/></el-icon><span>在新窗口打开</span></el-button> -->
          <el-button plain @click="viewVisible = false"><el-icon><Close/></el-icon><span>关闭</span></el-button>
        </div>
      </div>
    </template>

    <el-skeleton :rows="8" :loading="epIsLoading" animated>
      <template #default>
        <!-- 主要内容区域 START --> 

        <el-row :gutter="0">
          <el-col :span="18">
            <el-card>
              <template #header>

                <el-descriptions :column="3" direction="vertical" border size="small">
                  <el-descriptions-item><template #label>fxp_order_id</template>{{ orderData.fxp_order_id }}</el-descriptions-item>
                  <el-descriptions-item><template #label>分销采购单号</template>{{ orderData.fxp_order_sn }}</el-descriptions-item>
                  <el-descriptions-item><template #label>分销订单号</template>{{ orderData.fx_order_sn }}</el-descriptions-item>

                  <el-descriptions-item><template #label>分销商</template>
                    <div><SourceCodeTag :value="orderData.fx_shop_source_code" style="margin-right:4px;" />{{ orderData.fx_shop_name }}</div>
                    <div class="ep-cell-subtitle ep-overflow-text">{{ orderData.fx_merchant_name_cn }}</div>
                  </el-descriptions-item>
                  <el-descriptions-item><template #label>fx_merchant_id</template>{{ orderData.fx_merchant_id }}</el-descriptions-item>
                  <el-descriptions-item><template #label>fx_shop_id</template>{{ orderData.fx_shop_id }}</el-descriptions-item>

                  <el-descriptions-item><template #label>供应商</template>
                    <div><span class="el-tag el-tag--plain el-tag--small" style="margin-right:4px;">供应商</span>{{ orderData.sup_shop_name }}</div>
                    <div class="ep-cell-subtitle ep-overflow-text">{{ orderData.sup_merchant_name_cn }}</div>
                  </el-descriptions-item>
                  <el-descriptions-item><template #label>sup_merchant_id</template>{{ orderData.fx_merchant_id }}</el-descriptions-item>
                  <el-descriptions-item><template #label>sup_shop_id</template>{{ orderData.fx_shop_id }}</el-descriptions-item>

                  <!-- <el-descriptions-item :span="2"><template #label>收货人区域</template>
                    <span>{{ orderData.consignee_addr_province_text }} - {{ orderData.consignee_addr_city_text }} - {{ orderData.consignee_addr_district_text }} （{{ orderData.consignee_addr_area_code }}）</span>
                  </el-descriptions-item> -->

                  <!-- <el-descriptions-item :span="1"><template #label>收件人地址</template>{{ orderData.consignee_short_address }}</el-descriptions-item> -->
                </el-descriptions>

              </template>

              <!-- 商品信息 START -->
              <el-table ref="esDataTable" fit stripe highlight-current-row v-loading="epIsLoading" size="small"
                :data="orderData.orderGoods" :header-cell-style="{background:'#79bbff',color:'#fefefe'}">

                <el-table-column type="index" width="50" />
                
                <el-table-column label="货品名称" min-width="200" >
                  <template #default="scope">
                    <span>{{ scope.row.goods_item_name }}</span>
                    <div class="ep-cell-subtitle">{{ scope.row.sup_goods_item_id }}</div>
                  </template>
                </el-table-column>

                <el-table-column prop="goods_item_code" label="货品编码" min-width="100" />

                <el-table-column prop="cost_price" label="货品采购单价" min-width="100" />
                <el-table-column prop="quantity" label="数量" min-width="100" />
              </el-table>
              <!-- 商品信息 END -->
              
            </el-card>

          </el-col>

          <!-- 价格信息 START -->
          <el-col :span="6">
            <div style="padding: 20px">
              <div class="m-amount-row"><span>商品总价：</span><span>¥ {{ orderData.goods_amount }}</span></div>
              <div class="m-amount-row"><span>税费：</span><span>¥ {{ orderData.cat_tax }}</span></div>
              <div class="m-amount-row"><span>运费：</span><span>¥ {{ orderData.shipping_fee }}</span></div>
              <div class="m-amount-row"><span>优惠：</span><span class="m-discount-text">¥ {{ orderData.deductible_amount }}</span></div>
              <el-divider />
              <div class="m-amount-row"><span>实际支付：</span><span class="m-total-text">¥ {{ orderData.pay_amount }}</span></div>
            </div>
          </el-col>
          <!-- 价格信息 END -->
          
        </el-row>
        <!-- 主要内容区域 END --> 
      </template>
    </el-skeleton>

    <!-- <template #footer>
      <el-button type="primary" @click="onSubmit"><el-icon><Check/></el-icon><span>提交</span></el-button>
      <el-button type="default" @click="viewVisible = false"><el-icon><Close/></el-icon><span>取消</span></el-button>
    </template> -->
  </el-dialog>
</template>

<script>
import DataTableMixin from '@/mixin/DataTableMixin.js'
import { Link, Check, Close } from '@element-plus/icons-vue'
import SourceCodeTag from '@/components/v1/jxgocs/tags/SourceCodeTag'
import { apiV1GetFxpurchaseOrderObject } from '@/api/fenxiao.js'


export default {
  mixins: [ DataTableMixin ],
  name: 'order-detail-dialog',
  components: {
    Link, Check, Close,
    SourceCodeTag,
  },
  props: [],
  data() {
    return {
      editMode: 'insert',
      viewVisible: false,
      isLoading: false,

      fxp_order_id: '',
      orderData: [],

      // 检验规则
      formRules: {
      },
      // 拷贝规则
      cloneFields: [
      ],
    }
  },
  computed: {
  },
  methods: {
    // 显示view
    showView({ editMode, id } = {}) {
      this.editMode = editMode || 'insert'
      this.fxp_order_id = id
      // this.order_sn = order_sn
      this.viewVisible = true
    },
    // 当View打开时
    onViewOpen() {

      this.epIsLoading = true

      apiV1GetFxpurchaseOrderObject({
        fxp_order_id: this.fxp_order_id,
      }).then((res) => {
        this.orderData = res.data.data
        this.epIsLoading = false
      })
    },
    

    // ===================================================================================================

  },
  mounted() {
  }
};
</script>

<style scoped>
.m-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.m-amount-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* color: #303133; */
}
.m-discount-text {
  color: #67C23A;
}
.m-total-text {
  color: #f56C6C;
  font-weight: 700;
}
</style>
