<template>
  <el-container class="ep-page-container">
    <el-header class="ep-page-header">
      <div class="ep-page-header-breadcrumb">
        <el-breadcrumb><el-breadcrumb-item>系统</el-breadcrumb-item><el-breadcrumb-item>图片管理</el-breadcrumb-item><el-breadcrumb-item>图片列表</el-breadcrumb-item></el-breadcrumb>
      </div>
      <div class="ep-page-header-filter"></div>
      <div class="ep-page-header-toolbar">
        <el-button type="primary" @click="onUploadClick">图片上传</el-button>
        <!-- <el-button type="primary" @click="onExportCurrentShopGoodsClick">导出所有商品</el-button> -->
      </div>
    </el-header>

    <!-- 其他窗体 START -->
    <ImageUploadDialog ref="refImageUploadDialog" @submit="onEditSubmit" />
    <!-- 其他窗体 END -->

    <el-main class="ep-page-main">
      <!-- 查询面板 START -->
      <ImageSearchPanel ref="searchView" @search="onSearched" @reset="onSearchReset"/>
      <!-- 查询面板 END -->

      <!-- 数据表格 START -->
      <div class="el-card" style="margin:10px 0 80px 0;">
        <!-- 数据表格-过滤标签栏 START -->
        <el-tabs type="card" v-model="dataTableFilter" @tab-change="onDataTableTabChange">
          <el-tab-pane label="全部" name="__ALL__"></el-tab-pane>
          <!-- <el-tab-pane label="未审核" name="0"></el-tab-pane> -->
          <!-- <el-tab-pane label="已审核" name="1"></el-tab-pane> -->
        </el-tabs>
        <!-- 数据表格-过滤标签栏 END -->

        <!-- 数据表格 START -->
        <div class="ep-table-panel-wrap">
          <el-table ref="epDataTable" fit size="small" v-loading="epIsLoading"
            :data="epDataList" :header-cell-style="{background:'#eef1f6',color:'#606266'}" lazy>
            <!-- :height="esFixedTableHeight"  -->
            <!--  @selection-change="onTableSelectionChange" row-key="entity_id" :tree-props="{ children: 'children', hasChildren: 'has_children' }" -->

            <el-table-column type="selection" width="50" />

            <!-- <el-table-column type="expand">
              <template #default="scope">
                <GoodsExpandDetailView :skus="scope.row.skus"/>
              </template>
            </el-table-column> -->
            
            <el-table-column label="图片图" width="100" style="z-index: 4000;">
              <template #default="scope">
                <el-image class="m-main-image" :src="checkImageUrl(scope.row.image_uri)" fit="contain" @click="onImageClick(scope.row.image_uri)">
                  <template #error><div class="image-slot"><el-icon><IconPicture/></el-icon></div></template>
                </el-image>
              </template>
            </el-table-column>

            <el-table-column label="所属商户" min-width="150">
              <template #default="scope">
                <template v-if="scope.row.merchant_name != '' && scope.row.merchant_name != null">
                  <div class="ep-overflow-text">{{ scope.row.merchant_name }}</div>
                </template>
                <template v-else>-</template>
                <!-- <span class="ep-overflow-text"><span class="el-tag el-tag--plain el-tag--small" style="margin-right:4px;">供应商</span>{{ scope.row.shop_name }}</span> -->
              </template>
            </el-table-column>


            <el-table-column label="创建时间" min-width="100">
              <template #default="scope">
                <span v-html="$$utils.toDataTimeHtml(scope.row.created_at)"></span>
              </template>
            </el-table-column>
            
            <el-table-column label="图片链接" min-width="350" ><!-- fixed -->
              <template #default="scope">
                <el-input v-model="scope.row.image_uri" readonly>
                  <template #append><el-button @click="onCopyClick(scope.row.image_uri)">复制</el-button></template>
                </el-input>
              </template>
            </el-table-column>

            <!-- <el-table-column label="操作" min-width="150" fixed="right">
              <template #default="scope">
                <span class="ep-action-button primary" @click="onUpdateClick(scope.row.sup_goods_item_id)">编辑</span>
                <span class="ep-action-line"/>
                <span class="ep-action-button danger" @click="onDeleteClick(scope.row.sup_goods_item_id)">删除</span>
              </template>
            </el-table-column> -->
          </el-table>

        </div>
        <!-- 数据表格 END -->
      </div>
      <!-- 数据表格面板 END -->

      <!-- 数据页面固定footer START -->
      <div class="ep-float-footer">
        <div class="ep-float-footer-toolbar">
          <el-button plain class="ep-button-select-all" @click="$refs.epDataTable.toggleAllSelection()">
            <span class="ep-button-select-all-text1">已选中 <span class="ep-button-select-all-text2">{{ epMultipleSelection.length }}</span> / <span class="ep-button-select-all-text2">{{ epDataList.length }}</span> 项</span>
          </el-button>
          
        </div>
        <div class="ep-float-footer-pager">
          <ep-pagination :ep-page="this.epPage" :ep-total="this.epTotal" :ep-page-size="this.epPageSize" @pagination-change="onPaginationChange"/>
        </div>
      </div>
      <!-- 数据页面固定footer END -->
    </el-main>

  </el-container>
</template>

<script>
import DataTableMixin from '@/mixin/DataTableMixin.js'
import { Edit, ArrowDown, Picture as IconPicture } from '@element-plus/icons-vue'
import ImageUploadDialog from '@/views/v1/system/image/list/ImageUploadDialog'
import ImageSearchPanel from '@/views/v1/system/image/list/ImageSearchPanel'
import SourceCodeTag from '@/components/v1/jxgocs/tags/SourceCodeTag'
import { apiV1GetImagePage } from '@/api/file.js'
import AuthUtils from '@/utils/auth-utils.js'
import GlobalConfig from '@/config/index.js'


export default {
  mixins: [ DataTableMixin ],
  name: "sup-goods-item-list-page",
  components: {
    Edit, IconPicture,
    ImageUploadDialog,
    ImageSearchPanel,
    SourceCodeTag,
  },
  props: [],
  data() {
    return {
      shop_id: '',
      current_merchant_id: '',
      current_shop_id: '',
      //
      activeName: 'first',
      epPageSize: 20,
      dataTableFilter: '__ALL__',
      //
      main_image_url: '',
    }
  },
  computed: {},
  methods: {
    getFilter() {
      let data = {}
      if (AuthUtils.getCurrentShopId()) {
        data = { merchant_id: AuthUtils.getCurrentMerchantpId(), }
      }
      return data
    },
    getPage({reload = false} = {}) {
      let paginationData = this.getPagination(reload)
      let filterData = this.getFilter()
      let searchData = this.$refs.searchView.getSearchFilter()
      let queryData = Object.assign(paginationData, filterData, searchData)

      this.epIsLoading = true

      apiV1GetImagePage(queryData).then((res) => {
        this.epDataList = res.data.data
        this.setPaginationTotal(res.data.pagination.total)
        this.epIsLoading = false
      })
    },

    // ===================================================================================================

    // 点击添加
    onAddClick() {
      let newUrl = this.$router.resolve({ path: `/v1/goods/goods/add` })
      window.open(newUrl.href, '_blank')
    },
    // 点击查看
    onDetailClick(id) {
      this.$router.push({ name: 'MerchantsDetailPage', params: { id: id }})
    },
    // 点击修改
    onUpdateClick(id) {
      let newUrl = this.$router.resolve({ path: `/v1/goods/sup-goods-item/edit/${id}` })
      window.open(newUrl.href, '_blank')
    },
    // 点击删除
    onDeleteClick(id) {
      this.resolveDeleteConfirm().then(() => {
        apiV1DeleteSupGoodsItem({
          sup_goods_item_id: id,
        }).then((res) => {
          this.getPage()
          this.$message.success("操作成功")
        })
      }).catch(()=>{})
    },

    // 点击触发 - 货品导入
    onUploadClick() {
      this.$refs.refImageUploadDialog.showView({ editMode: "insert" })
    },

    // 编辑提交
    onEditSubmit(result) {
      this.getPage()
      // this.$message.success("操作成功")
    },


    // 回调触发 - 查询
    onSearched(data) {
      this.getPage({reload: true})
    },
    // 回调触发 - 查询重置
    onSearchReset() {
      this.getPage({reload: true})
    },
    // Change触发 - 数据表Tab
    onDataTableTabChange(name) {
      this.getPage({reload: true})
    },



    // 点击 打开图片链接
    onImageClick(url) {
      if (url != '' && url != null) { window.open(url) }
    },

    onCopyClick(text) {
      navigator.clipboard.writeText(text); this.$message.success("内容已复制")
    },

    // ===================================================================================================

    checkImageUrl(url) {
      return (url == '' || url == null) ? '/static/images/default_image.png' : url
    },



  },
  watch: {},
  mounted() {
    // this.$$emitter.on('current-shop-changed', (shop_id) => {
    //   this.getPage()
    // })

    this.$nextTick(() => {
      this.getPage()
    })
  }
}
</script>

<style scoped>
.m-main-image{ width:80px; height:80px; margin: 4px 0; cursor: pointer;}
.m-main-image .el-image { padding: 4px;  max-width: 80px;  max-height: 80px;  width: 100%;  height: 80px; }
.m-main-image .image-slot { display: flex;  justify-content: center;  align-items: center;  width: 100%;  height: 100%;  background: #d4d7de;  color: var(--el-text-color-secondary);  font-size: 30px; }
.m-main-image .image-slot .el-icon { font-size: 30px; }
</style>
