<template>
  <el-container class="ep-page-container">
    <el-header class="ep-page-header">
      <div class="ep-page-header-breadcrumb">
        <el-breadcrumb><el-breadcrumb-item>分销</el-breadcrumb-item><el-breadcrumb-item>分销商</el-breadcrumb-item><el-breadcrumb-item>供货关系管理</el-breadcrumb-item></el-breadcrumb>
      </div>
      <div class="ep-page-header-filter"></div>
      <div class="ep-page-header-toolbar">
        <!-- <el-button type="primary" @click="onUploadClick">货品导入</el-button> -->
        <el-button type="primary" @click="onExportCurrentShopGoodsClick">导出所有货品</el-button>
      </div>
    </el-header>

    <!-- 其他窗体 START -->
    <GoodsItemDetailDialog ref="refGoodsItemDetailDialog" @submit="onEditSubmit" />
    <!-- 其他窗体 END -->

    <el-main class="ep-page-main">
      <!-- 查询面板 START -->
      <GoodsItemSearchPanel ref="searchView" @search="onSearched" @reset="onSearchReset"/>
      <!-- 查询面板 END -->

      <!-- 数据表格 START -->
      <div class="el-card" style="margin:10px 0 80px 0;">
        <!-- 数据表格-过滤标签栏 START -->
        <el-tabs type="card" v-model="dataTableFilter" @tab-change="onDataTableTabChange">
          <el-tab-pane label="全部" name="__ALL__"></el-tab-pane>
          <el-tab-pane label="有变动" name="1"></el-tab-pane>
          <!-- <el-tab-pane label="已审核" name="1"></el-tab-pane> -->
        </el-tabs>
        <!-- 数据表格-过滤标签栏 END -->

        <!-- 数据表格 START -->
        <div class="ep-table-panel-wrap">
          <el-table ref="epDataTable" size="small" v-loading="epIsLoading"
            :data="epDataList" :header-cell-style="{background:'#eef1f6',color:'#606266'}"
            :row-class-name="funRowClassName" lazy>
            <!-- :height="esFixedTableHeight"  -->
            <!--  @selection-change="onTableSelectionChange" row-key="entity_id" :tree-props="{ children: 'children', hasChildren: 'has_children' }" -->

            <el-table-column type="selection" width="50" />

            <!-- <el-table-column type="expand">
              <template #default="scope">
                <GoodsExpandDetailView :skus="scope.row.skus"/>
              </template>
            </el-table-column> -->
            
            <el-table-column label="货品主图" width="100" fixed>
              <template #default="scope">
                <el-badge :is-dot="scope.row.oms_sgi_updated" class="item">
                  <el-image class="m-main-image" :src="checkImageUrl(scope.row.main_image_url)" fit="contain">
                    <template #error><div class="image-slot"><el-icon><IconPicture/></el-icon></div></template>
                  </el-image>
                </el-badge>
              </template>
            </el-table-column>

            <el-table-column label="货品名称" min-width="230" fixed>
              <template #default="scope">
                {{ scope.row.sku_name }}
                <div class="ep-cell-subtitle">{{ scope.row.sku_id }}</div>
                <template v-if="scope.row.oms_sku_type == 10"><el-tag type="success">OMS分销</el-tag></template>
                <template v-if="scope.row.is_near_lifecycle_end == 1"><el-tag type="warning">近效期</el-tag></template>
              </template>
            </el-table-column>

            <!-- <el-table-column label="所属商户/店铺" min-width="150">
              <template #default="scope">
                <el-popover placement="bottom" :width="250" trigger="click">
                  <template #reference>
                    <span class="ep-overflow-text"><SourceCodeTag :value="scope.row.shop_source_code" style="margin-right:4px;" />{{ scope.row.shop_name }}</span>
                  </template>
                  <div>
                    <div><SourceCodeTag :value="scope.row.shop_source_code" style="margin-right:4px;" />{{ scope.row.shop_name }}</div>
                    <div class="ep-cell-subtitle ep-overflow-text">{{ scope.row.merchant_name }}</div>
                  </div>
                </el-popover>
              </template>
            </el-table-column> -->

            <el-table-column prop="goods_sku" label="货品编码" min-width="130" />

            <!-- <el-table-column label="创建时间" min-width="100">
              <template #default="scope">
                <span v-html="$$utils.toDataTimeHtml(scope.row.created_at)"></span>
              </template>
            </el-table-column> -->
            
            
            <el-table-column label="供应商货品名称" min-width="230" ><!-- fixed -->
              <template #default="scope">
                {{ scope.row.sup_goods_item_name }}
                <div class="ep-cell-subtitle">{{ scope.row.ref_sup_goods_item_id }}</div>
              </template>
            </el-table-column>
            
            <el-table-column label="供应商" min-width="150">
              <template #default="scope">
                <el-popover placement="bottom" :width="250" trigger="click">
                  <template #reference>
                    <span class="ep-overflow-text"><SourceCodeTag :value="scope.row.sup_shop_source_code" style="margin-right:4px;" />{{ scope.row.sup_shop_name }}</span>
                  </template>
                  <div>
                    <div><SourceCodeTag :value="scope.row.sup_shop_source_code" style="margin-right:4px;" />{{ scope.row.sup_shop_name }}</div>
                    <div class="ep-cell-subtitle ep-overflow-text">{{ scope.row.sup_merchant_name }}</div>
                  </div>
                </el-popover>
              </template>
            </el-table-column>

            <el-table-column prop="sup_goods_item_code" label="供应商货品编码" min-width="130" />

            <el-table-column prop="sup_cost_price" label="供应商供货价" min-width="100" />

            <el-table-column prop="sup_total_tax_rate" label="货品税率（%）" min-width="100" />

            <!-- <el-table-column label="供应商仓库" min-width="130" >
              <template #default="scope">
                {{ scope.row.store_code_name }}
                <div class="ep-cell-subtitle">{{ scope.row.store_code_code }}</div>
              </template>
            </el-table-column> -->

            <el-table-column label="供应商仓库" min-width="130" >
              <template #default="scope">
                {{ scope.row.store_code_dname }}
                <div class="ep-cell-subtitle">{{ scope.row.store_code_dcode }}</div>
              </template>
            </el-table-column>

            <el-table-column min-width="130" >
              <template #header>
                <el-popover ref="popover" placement="top" title="备注" :width="200" trigger="hover" content="仅供参考，仓库货物更新较快，以实收效期为准">
                  <template #reference>
                    <div><span>效期到期日</span><el-icon color="#F56C6C" size="16px"><WarningFilled /></el-icon></div>
                  </template>
                </el-popover>
              </template>
              <template #default="scope">
                <template v-if="scope.row.sup_lifecycle_end_time"><span v-html="$$utils.toDate(scope.row.sup_lifecycle_end_time)"></span></template>
                <template v-else>-</template>
              </template>
            </el-table-column>
            
            <el-table-column label="供应商可用库存" min-width="130" ><!-- fixed -->
              <template #default="scope">
                <template v-if="scope.row.available_quantity"><span>{{ scope.row.available_quantity }}</span></template>
                <template v-else><span>0</span></template>
              </template>
            </el-table-column>

            <el-table-column label="操作" min-width="150" fixed="right">
              <template #default="scope">
                <span class="ep-action-button primary" @click="onDetailClick(scope.row)">查看变动</span>
                <!-- <span class="ep-action-line"/> -->
                <!-- <span class="ep-action-button danger" @click="onDeleteClick(scope.row.sku_id)">删除</span> -->
              </template>
            </el-table-column>
          </el-table>

        </div>
        <!-- 数据表格 END -->
      </div>
      <!-- 数据表格面板 END -->

      <!-- 数据页面固定footer START -->
      <div class="ep-float-footer">
        <div class="ep-float-footer-toolbar">
          <el-button plain class="ep-button-select-all" @click="$refs.epDataTable.toggleAllSelection()">
            <span class="ep-button-select-all-text1">已选中 <span class="ep-button-select-all-text2">{{ epMultipleSelection.length }}</span> / <span class="ep-button-select-all-text2">{{ epDataList.length }}</span> 项</span>
          </el-button>
          <!-- <el-button type="primary" :disabled="getMultipleSelectionCount() == 0" @click="onCheckClick">审核</el-button>
          <el-button type="primary" :disabled="getMultipleSelectionCount() == 0" @click="onCancelClick">作废</el-button>
          <el-button type="primary" :disabled="getMultipleSelectionCount() == 0" @click="onUnancelClick">恢复</el-button> -->
          <!-- <el-button v-permission="['A0002']" type="primary" :disabled="getMultipleSelectionCount() == 0" @click="onExportYunsutongMaskClick">导出订单</el-button> -->
          <!-- <el-button v-permission="['A0003']" type="primary" :disabled="getMultipleSelectionCount() == 0" @click="onExportYunsutongClick">导出完整订单</el-button> -->

          <!-- <el-dropdown split-button type="primary" style="margin-left:12px;" @click="onExportHainanCEB311Click">
            <span>导出：CEB格式</span>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item @click="onExportYunsutongClick">导出：云速通</el-dropdown-item>
                <el-dropdown-item @click="onExportYunsutongMaskClick">导出：云速通-掩码</el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown> -->
          
        </div>
        <div class="ep-float-footer-pager">
          <ep-pagination :ep-page="this.epPage" :ep-total="this.epTotal" :ep-page-size="this.epPageSize" @pagination-change="onPaginationChange"/>
        </div>
      </div>
      <!-- 数据页面固定footer END -->
    </el-main>

  </el-container>
</template>

<script>
import DataTableMixin from '@/mixin/DataTableMixin.js'
import { Edit, ArrowDown, Picture as IconPicture, WarningFilled } from '@element-plus/icons-vue'
import GoodsItemDetailDialog from '@/views/v1/fenxiao/purchase/goods_item/GoodsItemDetailDialog'
import GoodsItemSearchPanel from '@/views/v1/fenxiao/purchase/goods_item/GoodsItemSearchPanel'
import SourceCodeTag from '@/components/v1/jxgocs/tags/SourceCodeTag'
import { apiV1GetFxGoodsItemsPage } from '@/api/fenxiao.js'
import AuthUtils from '@/utils/auth-utils.js'
import GlobalConfig from '@/config/index.js'


export default {
  mixins: [ DataTableMixin ],
  name: "sup-goods-item-list-page",
  components: {
    Edit, IconPicture, WarningFilled,
    GoodsItemDetailDialog,
    GoodsItemSearchPanel,
    SourceCodeTag,
  },
  props: [],
  data() {
    return {
      shop_id: '',
      current_merchant_id: '',
      current_shop_id: '',
      //
      activeName: 'first',
      epPageSize: 20,
      dataTableFilter: '__ALL__',
      //
      main_image_url: '',
    }
  },
  computed: {},
  methods: {
    getFilter() {
      let data = {}
      if (AuthUtils.getCurrentShopId()) {
        data = {shop_id: AuthUtils.getCurrentShopId(), show_mode: 'merchant' }
      }
      if (this.dataTableFilter == 1) {
        data.oms_sgi_updated = 1
      }
      return data
    },
    getPage({reload = false} = {}) {
      let paginationData = this.getPagination(reload)
      let filterData = this.getFilter()
      let searchData = this.$refs.searchView.getSearchFilter()
      let queryData = Object.assign(paginationData, filterData, searchData)

      this.epIsLoading = true

      apiV1GetFxGoodsItemsPage(queryData).then((res) => {
        this.epDataList = res.data.data
        this.setPaginationTotal(res.data.pagination.total)
        this.epIsLoading = false
      })
    },

    // ===================================================================================================

    // 点击添加
    onAddClick() {
      let newUrl = this.$router.resolve({ path: `/v1/goods/goods/add` })
      window.open(newUrl.href, '_blank')
    },
    // 点击查看
    onDetailClick(rowObj) {
      this.$refs.refGoodsItemDetailDialog.showView({ editMode: "update", id: rowObj.sku_id, id2: rowObj.ref_sup_goods_item_id })
    },
    // 点击修改
    onUpdateClick(id) {
      let newUrl = this.$router.resolve({ path: `/v1/goods/goods-item/edit/${id}` })
      window.open(newUrl.href, '_blank')
    },
    // 点击删除
    onDeleteClick(id) {
      // this.resolveDeleteConfirm().then(() => {
      //   apiV1DeleteGoodsItem({
      //     sku_id: id,
      //   }).then((res) => {
      //     if (res.data.code == 0) { this.$message.success('操作成功'); this.getPage() } else { this.$message.error(res.data.message) }
      //   })
      // }).catch(()=>{})
    },

    // 点击触发 - 货品导入
    onUploadClick() {
      this.$refs.refSupGoodsItemUploadDialog.showView({ editMode: "insert" })
    },

    // 编辑提交
    onEditSubmit(result) {
      this.getPage()
      // this.$message.success("操作成功")
    },


    // 回调触发 - 查询
    onSearched(data) {
      this.getPage({reload: true})
    },
    // 回调触发 - 查询重置
    onSearchReset() {
      this.getPage({reload: true})
    },
    // Change触发 - 数据表Tab
    onDataTableTabChange(name) {
      this.getPage({reload: true})
    },




    // 更多按钮触发
    onMoreButtonClick(commandObj) {
      // console.log(commandObj)
      switch (commandObj.index) {
        case 'redeclare':
          this.onDeclareClick([commandObj.id], 1)
          break;
      }
    },


    // 点击 导出当前店铺商品
    onExportCurrentShopGoodsClick() {
      var url = `${GlobalConfig.host}api/admin/v1/fenxiao/goods-items/actions/export-shop-goods?shop_id=` + AuthUtils.getCurrentShopId()
      window.open(url) 
    },

    // ===================================================================================================

    // 实体行样式
    funRowClassName({  row,  rowIndex,}) {
      if (row.entity_type === 'merchant') {
        return 'jxgocs-merchant-row'
      } else if (row.entity_type === 'shop') {
        return 'jxgocs-shop-row'
      }
      return ''
    },

    checkImageUrl(url) {
      return (url == '' || url == null) ? '/static/images/default_image.png' : url
    },


    // 获取校区pair
    // getCampusPairData() {
    //   apiGetCampusPairs().then((res) => {
    //     this.campusPairs = res.data.data
    //   })
    // },

  },
  watch: {},
  mounted() {
    this.$$emitter.on('current-shop-changed', (shop_id) => {
      this.getPage()
    })

    this.$nextTick(() => {
      this.getPage()
    })
  }
}
</script>

<style scoped>
.m-main-image{ width:80px; height:80px; margin: 4px 0; }
.m-main-image .el-image { padding: 4px;  max-width: 80px;  max-height: 80px;  width: 100%;  height: 80px; }
.m-main-image .image-slot { display: flex;  justify-content: center;  align-items: center;  width: 100%;  height: 100%;  background: #d4d7de;  color: var(--el-text-color-secondary);  font-size: 30px; }
.m-main-image .image-slot .el-icon { font-size: 30px; }
.item { margin-top: 10px; margin-right: 40px; }
</style>
