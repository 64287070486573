<template>
  <el-tag :type="tagType" effect="dark">{{ tagLabel }}</el-tag>
</template>

<script>
export default {
  name: "check-state-tag",
  components: {
  },
  props: {
    state: {
      type: Number,
    },
  },
  data() {
    return {};
  },
  computed: {
    tagType() {
      switch (this.state) {
        case 0:
          return 'danger'
        case 1:
          return 'success'
        default:
          return 'info'
      }
    },
    tagLabel() {
      switch (this.state) {
        case 0:
          return '未审核'
        case 1:
          return '已审核'
        default:
          return '未知'
      }
    }
  },
  watch: {
  },
  methods: {
  },
  mounted() {
  }
};
</script>

<style scoped>
</style>
