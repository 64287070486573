<template>
  <el-container class="ep-page-container">
    <el-header class="ep-page-header">
      <div class="ep-page-header-breadcrumb">
        <el-breadcrumb><el-breadcrumb-item>系统</el-breadcrumb-item><el-breadcrumb-item>硬件</el-breadcrumb-item><el-breadcrumb-item>UKEY信息</el-breadcrumb-item></el-breadcrumb>
      </div>
      <div class="ep-page-header-filter"></div>
      <div class="ep-page-header-toolbar"></div>
    </el-header>
    <!-- 其他窗体 START -->
    <!-- 其他窗体 END -->
    <el-main class="ep-page-main">
      <el-card :body-style="{padding:'0px', 'background-color':'#f7f8fa', 'padding': '16px'}">
        <!-- 提示 START -->
        <el-alert type="warning" show-icon :closable="false" title="UKEY信息" style="margin-bottom:18px;max-width:1000px;border:1px solid">
          <div>请先输入UKEY密码，然后点击功能按钮获取对应的Ukey信息。</div>
          <div>注意！！大部分UKEY有5次的密码尝试次数限制，错误次数过多即会锁定UKEY，请谨慎操作！</div>
        </el-alert>
        <!-- 提示 END -->

        <el-form ref="form1" label-width="175px">
          <el-form-item>
            <template #label>
              <span>UKEY密码：</span>
              <template v-if="hasSavePassword"><el-tag type="success" effect="light" size="large">已记录</el-tag></template>
              <template v-else><el-tag type="info" effect="light" size="large">未记录</el-tag></template>
            </template>
            <el-input type="password" placeholder="请输入UKEY密码" v-model="mUkeyPassword" style="width:300px;" />
            <el-button type="primary" @click="onPasswordSaveClick" style="margin-left:20px;">保存</el-button>
            <el-button type="primary" plain @click="onPasswordClearClick">清空</el-button>
          </el-form-item>
        </el-form>

      </el-card>

      <el-card style="margin-top:10px;">
        <template #header>
          <el-button type="primary" @click="onGetKeyNameClick">获取证书号</el-button>
          <el-button type="primary" @click="onGetKeyCertClick">获取签名证书</el-button>
          <!-- <el-button type="primary" @click="onGetEnvCertClick">获取加密证书</el-button> -->
        </template>

        <!-- 响应结果 START -->
        <el-form ref="form2" label-width="100px">
          <el-form-item label="操作结果：">
            <el-input type="textarea" :autosize="{ minRows: 6, maxRows: 10}" v-model="mActionResult"/>
          </el-form-item>
          <el-form-item label="原始响应：">
            <el-input type="textarea" :autosize="{ minRows: 6, maxRows: 10}" v-model="mActionLog"/>
          </el-form-item>
        </el-form>
        <!-- 响应结果 END -->
      </el-card>

    </el-main>
  </el-container>
</template>

<script>
// import { Edit, ArrowDown } from '@element-plus/icons-vue'
import StorageUtils from '@/utils/storage-utils.js'
import AuthUtils from '@/utils/auth-utils.js'


export default {
  name: "ukey-info-page",
  components: {
    // Edit, ArrowDown,
  },
  props: [],
  data() {
    return {
      // 是否保存密码
      hasSavePassword: false,
      mUkeyPassword: '',
      mActionResult: '',
      mActionLog: '',
      //
      WEportClient: null,
    }
  },
  computed: {},
  watch: {},
  methods: {
    // 点击 取Ukey证书号
    onGetKeyNameClick() {
      let vueThis = this

      if (vueThis._CheckUkeyPassword() == false) return
      this.mActionResult = ''
      this.mActionLog = ''

      this.WEportClient.isInstalledTest(this.WEportClient.cusSpcGetCertNo, '', '', function(msg, msgJson){
        console.log(msgJson)
        vueThis.mActionLog = msgJson

        var resultContent = ''
        if (msg.Result) {
          resultContent = msg.Data[0]
        } else {
          msg.Error.forEach(element => {
            resultContent += element + '|'
          });
        }
        vueThis.mActionResult = resultContent
      });
    },
    // 点击 取Ukey证书
    onGetKeyCertClick() {
      let vueThis = this

      if (vueThis._CheckUkeyPassword() == false) return
      this.mActionResult = ''
      this.mActionLog = ''

      this.WEportClient.isInstalledTest(this.WEportClient.cusSpcGetSignCertAsPEM, '', '', function(msg, msgJson){
        console.log(msgJson)
        vueThis.mActionLog = msgJson

        var resultContent = ''
        if (msg.Result) {
          resultContent = msg.Data[0]
        } else {
          msg.Error.forEach(element => {
            resultContent += element + '|'
          });
        }
        vueThis.mActionResult = resultContent
      });
    },

    /*
    // 获取加密证书(未启用)
    onGetEnvCertClick() {
      let vueThis = this

      if (vueThis._CheckUkeyPassword() == false) return
      this.mActionResult = ''
      this.mActionLog = ''

      this.WEportClient.isInstalledTest(this.WEportClient.cusSpcGetEnvCertAsPEM, '', '', function(msg, msgJson){
        console.log(msgJson)
        vueThis.mActionLog = msgJson

        var resultContent = ''
        if (msg.Result) {
          resultContent = msg.Data[0]
        } else {
          msg.Error.forEach(element => {
            resultContent += element + '|'
          });
        }
        vueThis.mActionResult = resultContent
      });
    },
    */

    // 点击触发 - 保存UKEY Password
    onPasswordSaveClick() {
      if (this.mUkeyPassword != '') {
        StorageUtils.set('ukp-' + AuthUtils.getUserId(), this.mUkeyPassword)
        this.hasSavePassword = true
        this.$message.success("操作成功")
      }
    },
    // 点击触发 - 清空UKEY Password
    onPasswordClearClick() {
        this.mUkeyPassword = ''
        StorageUtils.remove('ukp-' + AuthUtils.getUserId(), this.mUkeyPassword)
        this.hasSavePassword = false
        this.$message.success("操作成功")
    },

    // ===================================================================================================

    // 检查Ukey密码
    _CheckUkeyPassword() {
      if (this.mUkeyPassword == '') {
        this.$message.error("请先输入UKEY密码，然后再尝试获取信息")
        return false
      }
      return true
    },
    // 获取当前用户ukp
    getCurretUserUkeyPassword() {
      var val = StorageUtils.get('ukp-' + AuthUtils.getUserId())
      // console.log(val)
      if (val != undefined && val != '') {
        this.mUkeyPassword = val
        this.hasSavePassword = true
      } else {
        this.mUkeyPassword = ''
        this.hasSavePassword = false
      }
    },

  },
  mounted() {
    this.WEportClient = window.EportClient
    this.$nextTick(() => {
      this.getCurretUserUkeyPassword()
    })
  }
}
</script>

<style scoped>
.el-form {
  width: 1000px;
  margin-left: 0;
  margin-right: auto;
}
</style>
