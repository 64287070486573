import { request } from '@/utils/request-utils.js';


// ================================================================================================
// 文件 - 图片 V1
// ================================================================================================
// 图片上传预操作
export function apiV1ImageActionPreUpload(data) { return request({url: `/api/common/v1/file/image/actions/pre-upload`, method: 'get', params: data, commonApi: true}) }

// 查询分页 图片
export function apiV1GetImagePage(data) { return request({url: `/api/common/v1/file/images`, method: 'get', params: data, commonApi: true}) }

