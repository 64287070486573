<template>
  <el-container class="ep-page-container">
    <el-header class="ep-page-header">
      <div class="ep-page-header-breadcrumb">
        <el-breadcrumb><el-breadcrumb-item>分销</el-breadcrumb-item><el-breadcrumb-item>分销商</el-breadcrumb-item><el-breadcrumb-item>分销采购表</el-breadcrumb-item></el-breadcrumb>
      </div>
      <div class="ep-page-header-filter"></div>
      <div class="ep-page-header-toolbar">
        <!-- <el-button type="primary" @click="onOrderCatchClick">抓取订单<el-icon class="el-icon--right"><MoreFilled/></el-icon></el-button> -->
      </div>
    </el-header>
    <!-- 其他窗体 START -->
    <!-- <OrderCatchDialog ref="refOrderCatchDialog" @submit="onEditSubmit" /> -->
    <OrderDetailDialog ref="refOrderDetailDialog" />
    <!-- <OrderInfoEditView ref="refOrderInfoEditView" @submit="onEditSubmit" /> -->
    
    <!-- 其他窗体 END -->
    <el-main class="ep-page-main">
      <!-- 查询面板 START -->
      <OrderSearchPanel ref="searchView" @search="onSearched" @reset="onSearchReset" @export="onExport('')" @export2="onExport('2')"/>
      <!-- 查询面板 END -->

      <!-- 数据表格 START -->
      <div class="el-card" style="margin:10px 0 80px 0;">
        <!-- 数据表格-过滤标签栏 START -->
        <el-tabs type="card" v-model="dataTableFilter" @tab-change="onDataTableTabChange">
          <el-tab-pane label="全部" name="__ALL__"></el-tab-pane>
          <el-tab-pane label="未结算" name="0"></el-tab-pane>
          <el-tab-pane label="已结算" name="1"></el-tab-pane>
        </el-tabs>
        <!-- 数据表格-过滤标签栏 END -->

        <!-- 数据表格 START -->
        <!-- :height="esFixedTableHeight"  -->
        <div class="ep-table-panel-wrap">
          <el-table ref="epDataTable" fit stripe highlight-current-row size="small" v-loading="epIsLoading"
            :data="epDataList" :header-cell-style="{background:'#eef1f6',color:'#606266'}"
            @selection-change="onTableSelectionChange">
            
            <el-table-column type="selection" width="50" />

            <el-table-column label="分销采购单号" min-width="200">
              <template #default="scope">
                <el-link :underline="false" @click="onDetailClick(scope.row.fxp_order_id)">{{ scope.row.fxp_order_sn }}</el-link>
                <div class="ep-cell-subtitle">
                  <span>{{ scope.row.fx_order_sn }}</span>
                </div>
              </template>
            </el-table-column>

            <el-table-column label="分销商" min-width="150">
              <template #default="scope">
                <el-popover placement="bottom" :width="250" trigger="click">
                  <template #reference>
                    <span class="ep-overflow-text"><SourceCodeTag :value="scope.row.fx_shop_source_code" style="margin-right:4px;" />{{ scope.row.fx_shop_name }}</span>
                  </template>
                  <div>
                    <div><SourceCodeTag :value="scope.row.fx_shop_source_code" style="margin-right:4px;" />{{ scope.row.fx_shop_name }}</div>
                    <div class="ep-cell-subtitle ep-overflow-text">{{ scope.row.fx_merchant_name_cn }}</div>
                  </div>
                </el-popover>
              </template>
            </el-table-column>

            <el-table-column label="供应商" min-width="150">
              <template #default="scope">
                <el-popover placement="bottom" :width="250" trigger="click">
                  <template #reference>
                    <span class="ep-overflow-text"><span class="el-tag el-tag--plain el-tag--small" style="margin-right:4px;">供应商</span>{{ scope.row.sup_shop_name }}</span>
                  </template>
                  <div>
                    <div><span class="el-tag el-tag--plain el-tag--small" style="margin-right:4px;">供应商</span>{{ scope.row.sup_shop_name }}</div>
                    <div class="ep-cell-subtitle ep-overflow-text">{{ scope.row.sup_merchant_name_cn }}</div>
                  </div>
                </el-popover>
              </template>
            </el-table-column>

            <el-table-column label="下单时间" min-width="100">
              <template #default="scope">
                <span v-html="$$utils.toDataTimeHtml(scope.row.fxp_order_time)"></span>
              </template>
            </el-table-column>

            <el-table-column prop="pay_amount" label="支付金额" min-width="80" />

            <el-table-column label="订单状态" min-width="100" >
              <template #default="scope">
                <template v-if="scope.row.oms_fxp_order_state == 1"><el-tag type="success" effect="dark">已结算</el-tag></template>
                <template v-else><el-tag type="info" effect="dark">未结算</el-tag></template>
              </template>
            </el-table-column>

            <el-table-column label="操作" min-width="150" fixed="right">
              <template #default="scope">
                <!-- <span class="ep-action-button primary" @click="onUpdateClick(scope.row.order_id)">编辑</span> -->
                <template v-if="scope.row.admin_check_state == 0 && scope.row.order_state != 5">
                  <span class="ep-action-line"/>
                  <span class="ep-action-button primary" @click="onCheckClick([scope.row])">结算</span>
                </template>
                


                <!-- <span class="ep-action-button primary" @click="onSfexpressInfoEditClick(scope.row)">顺丰编辑</span> -->

              </template>
            </el-table-column>
          </el-table>
        </div>
        <!-- 数据表格 END -->
      </div>
      <!-- 数据表格面板 END -->

      <!-- 数据页面固定footer START -->
      <div class="ep-float-footer">
        <div class="ep-float-footer-toolbar">
          <el-button plain class="ep-button-select-all" @click="$refs.epDataTable.toggleAllSelection()">
            <span class="ep-button-select-all-text1">已选中 <span class="ep-button-select-all-text2">{{ epMultipleSelection.length }}</span> / <span class="ep-button-select-all-text2">{{ epDataList.length }}</span> 项</span>
          </el-button>
          <el-button type="primary" :disabled="true" @click="onCheckClick">结算</el-button>
          <!-- <el-button type="primary" :disabled="getMultipleSelectionCount() == 0" @click="onCancelClick">作废</el-button> -->
          <!-- <el-button type="primary" :disabled="getMultipleSelectionCount() == 0" @click="onUnancelClick">恢复</el-button> -->
          <!-- <el-button v-permission="['A0002']" type="primary" :disabled="getMultipleSelectionCount() == 0" @click="onExportYunsutongMaskClick">导出订单</el-button> -->
          <!-- <el-button v-permission="['A0003']" type="primary" :disabled="getMultipleSelectionCount() == 0" @click="onExportYunsutongClick">导出完整订单</el-button> -->

          <!-- <el-dropdown split-button type="primary" style="margin-left:12px;" @click="onExportHainanCEB311Click">
            <span>导出：CEB格式</span>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item @click="onExportYunsutongClick">导出：云速通</el-dropdown-item>
                <el-dropdown-item @click="onExportYunsutongMaskClick">导出：云速通-掩码</el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown> -->
          
        </div>
        <div class="ep-float-footer-pager">
          <ep-pagination :ep-page="this.epPage" :ep-total="this.epTotal" :ep-page-size="this.epPageSize" @pagination-change="onPaginationChange"/>
        </div>
      </div>
      <!-- 数据页面固定footer END -->

    </el-main>
    
  </el-container>
</template>

<script>
import DataTableMixin from '@/mixin/DataTableMixin.js'
import { Edit, ArrowDown, MoreFilled } from '@element-plus/icons-vue'
// import OrderCatchDialog from '@/views/v1/trade/order/list/OrderCatchDialog'
import OrderSearchPanel from '@/views/v1/fenxiao/purchase/list/OrderSearchPanel'
import OrderDetailDialog from '@/views/v1/fenxiao/purchase/list/OrderDetailDialog'
// import OrderInfoEditView from '@/views/v1/trade/order/list/OrderInfoEditView'

import SourceCodeTag from '@/components/v1/jxgocs/tags/SourceCodeTag'
import OrderStateTag from '@/components/v1/trade/tags/OrderStateTag'
import CheckStateTag from '@/components/v1/trade/tags/CheckStateTag'
// import EpStateTag from '@/components/v1/common/EpStateTag'
import { apiV1GetOrdersPage, apiOrdersActionCheck, apiOrdersActionCancel, apiOrdersActionUncancel } from '@/api/trade.js'
import { apiV1GetFxpurchaseOrdersPage } from '@/api/fenxiao.js'
import AuthUtils from '@/utils/auth-utils.js'
import GlobalConfig from '@/config/index.js'


export default {
  mixins: [ DataTableMixin ],
  name: "order-list-page",
  components: {
    MoreFilled, // Edit, ArrowDown,
    // OrderCatchDialog,
    OrderDetailDialog,
    // OrderInfoEditView,
    OrderSearchPanel,
    // OrderExpandDetailView,
    SourceCodeTag,
    OrderStateTag,
    CheckStateTag,
  },
  props: [],
  data() {
    return {
      shop_id: '',

      current_merchant_id: '',
      // 当前店铺id
      current_shop_id: '', // 32201908210407394928150064e55678

      dataTableFilter: '__ALL__',
    }
  },
  computed: {
    doneTodosCount () {
      return this.$store.state.info
    },
    doneTodosCount2 () {
      return this.$store.getters.formatInfo
    },
    message: {
      get () {
        return this.$store.state.info
      },
      set (value) {
        this.$store.commit('formatInfo', value)
      }
    },
  },
  watch: {
    // currentShopId(newValue, oldValue) {
    //   console.log(this.current_shop_id)
    //   // return this.store.state.info
    // }
  },
  methods: {
    getFilter() {
      let data = {}
      if (AuthUtils.getCurrentShopId()) {
        data.fx_shop_id = AuthUtils.getCurrentShopId()
      }
      if (this.dataTableFilter != '' && this.dataTableFilter != '__ALL__') {
        data.oms_fxp_order_state = this.dataTableFilter
      }
      return data
    },
    getPage({reload = false} = {}) {
      // console.log(this.$store.state.info); return
      
      let paginationData = this.getPagination(reload)
      let filterData = this.getFilter()
      let searchData = this.$refs.searchView.getSearchFilter(); console.log(searchData); 
      let queryData = Object.assign(paginationData, filterData, searchData)

      this.epIsLoading = true

      apiV1GetFxpurchaseOrdersPage(queryData).then((res) => {
        this.epDataList = res.data.data
        this.setPaginationTotal(res.data.pagination.total)
        this.epIsLoading = false
      })
      
    },


    // ===================================================================================================

    // 点击添加
    onAddClick(id) {
      // this.getPage()
      this.$refs.updateView.showView({ editMode: "insert" })
    },
    // 点击查看
    // onDetailClick(id) {
    //   this.$router.push({ name: 'MerchantsDetailPage', params: { id: id }})
    // },
    // 点击修改
    onUpdateClick(id) {
      this.$refs.refOrderInfoEditView.showView({ editMode: "update", id: id })
    },
    // 点击删除
    onDeleteClick(id) {
      // this.resolveDeleteConfirm().then(() => {
      //   apiDeleteMerchants({
      //     merchant_id: id,
      //   }).then((res) => {
      //     this.getPage()
      //     this.$message.success("操作成功")
      //   })
      // }).catch(()=>{})
    },

    // 编辑提交
    onEditSubmit(result) {
      this.getPage()
      this.$message.success("操作成功")
    },


    // 回调触发 - 查询
    onSearched(data) {
      this.getPage({reload: true})
    },
    // 回调触发 - 查询重置
    onSearchReset() {
      this.getPage({reload: true})
    },
    // Change触发 - 数据表Tab
    onDataTableTabChange(name) {
      this.getPage({reload: true})
    },

    
    // 点击触发 - 订单详情
    onDetailClick(id) {
      this.$refs.refOrderDetailDialog.showView({ editMode: "update", id: id })
    },


    // 更多按钮触发
    onMoreButtonClick(commandObj) {
      // console.log(commandObj)
      switch (commandObj.index) {
        case 'redeclare':
          this.onDeclareClick([commandObj.id], 1)
          break;
      }
    },

    // 点击 审核 时触发
    onCheckClick(inIds = []) {
      var ids = this.getSelectedIds(inIds, (val) => val.order_id)
      // console.log(ids); return
      this.$confirm("确定审核选定数据吗?", "提示", {
        // type: "warning"
      }).then(() => {
        apiOrdersActionCheck({
          ids: ids,
        }).then((res) => {
          this.getPage()
          this.$message.success("操作成功")
        })
      }).catch(()=>{})
    },
    // 点击作废时触发
    onCancelClick(inIds = []) {
      var ids = this.getSelectedIds(inIds, (val) => val.order_id)

      this.$confirm("确定作废选定的订单吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        apiOrdersActionCancel({
          ids: ids,
        }).then((res) => {
          this.getPage()
          this.$message.success("操作成功")
        })
      }).catch(()=>{})
    },
    // 点击恢复时触发
    onUnancelClick(inIds = []) {
      var ids = this.getSelectedIds(inIds, (val) => val.order_id)

      this.$confirm("确定恢复选定的订单吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        apiOrdersActionUncancel({
          ids: ids,
        }).then((res) => {
          this.getPage()
          this.$message.success("操作成功")
        })
      }).catch(()=>{})
    },

    onExportHainanCEB311Click(inIds = []) {
      var ids = this.getSelectedIds(inIds, (val) => val.order_id)
      var url = `${GlobalConfig.host}api/admin/trade/orders/action/export-hainan-ceb311?ids=${ids}`
      window.open(url) 
    },
    onExportYunsutongClick(inIds = []) {
      var ids = this.getSelectedIds(inIds, (val) => val.order_id)
      var url = `${GlobalConfig.host}api/admin/trade/orders/action/exportYunsutong?ids=${ids}`
      window.open(url) 
    },
    onExportYunsutongMaskClick(inIds = []) {
      var ids = this.getSelectedIds(inIds, (val) => val.order_id)
      var url = `${GlobalConfig.host}api/admin/trade/orders/action/exportYunsutong-mask?ids=${ids}`
      window.open(url) 
    },

    onOrderCatchClick() {
      this.$refs.refOrderCatchDialog.showView({ editMode: "insert" })
    },

    onExport(mode = '') {
      let filterData = this.getFilter()
      let searchData = this.$refs.searchView.getSearchFilter()
      let queryData = Object.assign(filterData, searchData)

      let params='';for(let key in queryData){if(queryData.hasOwnProperty(key)){params+=`${encodeURIComponent(key)}=${encodeURIComponent(queryData[key])}&`}};params=params.slice(0,-1)
      // console.log(queryData); console.log(mode);  return

      var url = `${GlobalConfig.host}api/admin/v1/fenxiao/fxpurchase-orders/actions/export` + mode + '?' + params
      
      window.open(url) 
    },


    // 点击 顺丰扩展信息编辑 触发
    onSfexpressInfoEditClick(row) {
      // this.$refs.refSfexpressInfoEditView.showView({ editMode: "insert", row: row })
    },

    /*
    bbb(row, column, rowIndex, columnIndex) {
      console.log(row)
      return "background-color:red"
    },
    */


    // ===================================================================================================


    // 获取校区pair
    // getCampusPairData() {
    //   apiGetCampusPairs().then((res) => {
    //     this.campusPairs = res.data.data
    //   })
    // },

  },
  mounted() {
    this.$$emitter.on('current-shop-changed', (shop_id) => {
      this.getPage()
    })

    this.$nextTick(() => {
      this.getPage()
    })
  }
}
</script>

<style scoped>
.m-shop-separation {
  margin: 4px 0;
  border-bottom: 1px solid #eee;
}
</style>
